@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap');

html {
    scroll-behavior: smooth;
}

.azh_btn_edit {
    padding: 0.2rem 0.3rem;
}

.azh_btn_education {
    padding: 0.2rem 0.3rem;
    margin-left: 20px;
}

/* svg related classes */
.st0 {
    fill: url(#SVGID_1_);
}
.st1 {
    fill: #ffffff;
}
.cls-1 {
    fill: #ffffff;
}

.st0-dashboard {
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.st0-services {
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}
